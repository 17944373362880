<template>
  <div>
    <div class="upper flex">
      <div class="bloсks graphic">
        <h1>......</h1>
        <TV :ticker="{s: 'ff'}" />
      </div>
      <div class="bloсks b-right">
        <h1>Test 2</h1>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TradingView"
}
</script>

<style scoped>

</style>
